import React, { useLayoutEffect } from "react";
import { articles } from "../ResearchPage";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import img3 from "../../assets/images/about/image3.png";
import Footer from "../../components/Footer";

const articlesData = {};
articles.forEach((item) => (articlesData[item.id] = item));

const ArticlesPage = () => {
  const { id } = useParams();
  const item = articlesData[id];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="grid mx-auto max-w-[90%] py-28 gap-14 grid-cols-1">
        <div className="flex flex-col gap-4 items-start">
          <div className="text-3xl font-[500]">{item.label}</div>
          <div className="text-xl">{item.caption}</div>
          <div className="italic flex text-xl gap-4 items-center ">
            <img
              src={img3}
              alt="author"
              className="w-[4rem] h-[4rem] object-contain"
            />
            James Baker
          </div>
          {item.para && (
            <div className="grid grid-cols-1 gap-10">
              <div className="flex flex-col gap-2 items-start">
                {item.para.map((_item) => {
                  return <div className="text-xl md:text-2xl">{_item}</div>;
                })}
              </div>

              <div className="flex flex-col w-full items-center gap-10">
                <div className="flex flex-col w-full gap-2  border rounded-md p-4 shadow-md items-start">
                  <div className="text-2xl font-[600] md:text-3xl">
                    {item.button1}
                  </div>
                  <div className="text-xl md:text-2xl">{item.button1Desc}</div>
                </div>
                <div className="flex flex-col gap-2 w-full  border rounded-md p-4 shadow-md items-start">
                  <div className="text-2xl font-[600] md:text-3xl">
                    {item.button2}
                  </div>
                  <div className="text-xl md:text-2xl">{item.button2Desc}</div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!item.para && (
          <>
            <div className="flex flex-col gap-28 items-center justify-center">
              {item.details.map((detailItem) => (
                <div className="w-full">
                  <h2 className="text-xl md:text-2xl mb-6 font-[500]">
                    {detailItem.title}
                  </h2>
                  {detailItem.listItems ? (
                    <div>
                      {detailItem.listItems.map((item, idx) => {
                        return (
                          <div className="flex text-md gap-1 my-2 md:text-xl pl-10 items-start">
                            {`${idx + 1}. `}
                            <div className="">
                              <span className="font-[600]">{item.head}</span>:{" "}
                              {item.data}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    detailItem.description.map((_item) => {
                      return (
                        <div className="text-md my-2 md:text-xl">{_item}</div>
                      );
                    })
                  )}
                  {detailItem.img && (
                    <img
                      src={detailItem.img}
                      alt="item"
                      className="md:w-2/3 mt-20 mx-auto w-full object-contain"
                    />
                  )}
                  {detailItem.imgText && (
                    <p className="text-md mt-8 italic max-w-[100%] md:max-w-[50%] mx-auto text-center">
                      {detailItem.imgText}
                    </p>
                  )}
                </div>
              ))}
            </div>
            {item.sources && <div className="flex flex-col gap-4 items-start justify-center">
              <h2 className="text-xl md:text-2xl text-left font-[500]">
                Sources
              </h2>
              {item.sources.map((detailItem, index) => (
                <div>
                  <h2 className="text-md md:text-xl">
                    {`${index + 1}. ${detailItem}`}
                  </h2>
                  <div className="text-xs md:text-sm">
                    {detailItem.description}
                  </div>
                </div>
              ))}
            </div>}
          </>
        )}
        {item.para && (
          <iframe
            src="https://scors-2023.replit.app/"
            title="score"
            className="w-full col-span-2 mt-10 min-h-[100vh]"
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default ArticlesPage;
