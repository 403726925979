// ContactSupport.jsx
import React, { useRef, useState } from "react";
import PrimaryBtn from "../PrimaryBtn";
import Popup from "reactjs-popup";
import { AiOutlineCheckCircle } from "react-icons/ai";
import contactImg from "../../assets/images/contact.png";
import { IoCloseCircle } from "react-icons/io5";
import emailjs from "emailjs-com";

const ContactSupport = ({ closeContact, contactPage = false }) => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "clinica_contact", // Your Service ID
        "template_8huos65", // Your Template ID
        formRef.current,
        "XsQHL9-BC3BJMc89O", // Your Public Key (User ID)
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowAlert(true);
          setLoading(false);
        },
        (error) => {
          console.log("There was an error sending the email:", error.text);
          alert("An error occurred, please try again.");
          setLoading(false);
        },
      );
  };

  const closeModal = () => {
    setShowAlert(false);
    closeContact();
  };

  return (
    <div
      className={`w-full custom:items-start items-center flex custom:flex-row flex-col ${
        !contactPage
          ? "rounded-lg border bg-slate-300 mt-10 custom:mt-10 mx-auto md:mt-32"
          : "bg-white py-10"
      } `}
    >
      {contactPage && (
        <IoCloseCircle
          onClick={closeContact}
          size={40}
          className="absolute z-[10] cursor-pointer top-2 md:right-5 right-2"
        />
      )}
      {contactPage && (
        <img
          src={contactImg}
          alt="contactImg"
          className="w-[50rem] object-contain"
        />
      )}
      <div className="flex flex-col justify-between items-start px-4 md:px-10 md:py-20 py-10 w-full">
        <h2 className="md:text-5xl text-2xl text-[#000] font-[500] mb-0">
          {contactPage ? "Reach out to the" : "Contact Support"}
        </h2>
        {contactPage && (
          <h2 className="md:text-5xl text-2xl text-[#000] font-[500] md:mb-12 mb-0">
            Clinica team
          </h2>
        )}
        <form ref={formRef} className="w-full" onSubmit={handleSubmit}>
          {contactPage && (
            <div className="mt-10 mb-8">
              <label
                className="block text-gray-500 text-sm font-[500] mb-2"
                htmlFor="user_email"
              >
                Email address
              </label>
              <input
                required
                type="email"
                id="user_email"
                name="user_email"
                className="shadow appearance-none border-[#c4c4c4] rounded w-full py-2 px-3 text-[#000] leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Your email"
              />
            </div>
          )}
          <div className="mb-10">
            <label
              className="block text-gray-500 text-sm font-[500] mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              required
              id="message"
              name="message"
              className="shadow appearance-none border-[#c4c4c4] rounded w-full py-2 px-3 text-[#000] leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Your message"
              rows="4"
            ></textarea>
          </div>
          <div className="flex items-center justify-between">
            <PrimaryBtn
              disabled={loading}
              className={
                "w-40 bg-[#0A1172] hover:scale-105 transition-all ease-in-out duration-200 text-slate-50"
              }
              text={loading ? "Sending..." : "Send Message"}
            />
          </div>
        </form>
      </div>
      <Popup
        onClose={closeModal}
        modal
        open={showAlert}
        position="center center"
      >
        <div className="flex justify-center rounded-lg gap-6 flex-col items-center bg-white h-[40vh] w-[40vw]">
          <AiOutlineCheckCircle size={200} color="green" />
          <h2 className="font-[600] text-center text-xl">
            Your message has been successfully submitted.
            <br /> Thank you for contacting us!
          </h2>
        </div>
      </Popup>
    </div>
  );
};

export default ContactSupport;
