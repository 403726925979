import React from "react";
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import ContactFooter from "../../components/ContactFooter";
import Footer from "../../components/Footer";
import img1 from "../../assets/images/MA_Section_Homepage.svg";
import img2 from "../../assets/images/medicalSvg.svg";
import MA_Article_Figure_2 from "../../assets/images/article/MA_Article_Figure_2.png";
import Image4 from "../../assets/images/article/Image4.png";
import Image5 from "../../assets/images/article/Image5.png";
import Image6 from "../../assets/images/article/Image6.png";
import rndImg1 from "../../assets/images/article/rnd/Image1.png";
import rndImg1_1 from "../../assets/images/article/rnd/image1_1.png";
import rndImg2 from "../../assets/images/article/rnd/Image2.png";
import rndImg3 from "../../assets/images/article/rnd/Image3.png";
import rndImg4 from "../../assets/images/article/rnd/Image4.png";
import rndImg5 from "../../assets/images/article/rnd/Image5.png";
import covidImg from "../../assets/images/article/covid.jpg";
import PrimaryBtn from "../../components/PrimaryBtn";
import article4Img from "../../assets/images/article/article4.png";

export const articles = [
  {
    name: "Article 1",
    articleImg: img1,
    caption:
      "Discover how advanced AI and data-driven strategies are revolutionizing Medical Affairs to bridge the gap in healthcare disparities, ensuring optimal treatment for all patients.",
    id: "article1",
    desc: "Addressing Disparities in Healthcare with Medical Affairs",
    label: "Addressing Disparities in Healthcare with Medical Affairs",
    details: [
      {
        title: "",
        description: [],
        img: img1,
      },
      {
        title: "",
        description: [
          "There is no question that, especially over the past few decades, the advancement of therapeutics and life sciences has been significant. Yet, disparities in healthcare persist across the United States and the rest of the world, affecting millions of patients. Research shows that between 60% and 75% of patients, on average across diseases, experience suboptimal treatment at some point in their journey¹²³. This issue not only impacts patient outcomes, but also has a significant financial cost for the US economy. According to a study funded by the National Institute of Health (NIH), the financial toll of health disparities in the United States amounts to $451 billion each year⁴​. Moreover, most of this economic burden is borne by disadvantaged groups. ",
          "Pharmaceutical and BioTech companies spend, on average, 50% of revenues on Medical and Commercial Affairs, but there is still a gap between patients and the best available treatments.",
        ],
      },
      {
        title: "Medical Strategy",
        description: [
          "Closing this gap between patients and the best treatment, and thus, addressing disparities in US healthcare requires a multifaceted approach, leveraging data to make targeted interventions through Medical Affairs. However, current sources of data offer incomplete, outdated information, making it a true challenge to really understand patient journeys. Despite the significant improvements in Real World Data (RWD), information covers only ~70% of patients in the US⁵⁶.",
          "New, advanced artificial intelligence techniques help fill in these data gaps, enabling a complete understanding of patient care across healthcare providers, insurance companies, and geographies.",
          "Clinica AI developed artificial intelligence algorithms to analyze RWD from diverse sources such as electronic medical records (EMR) and insurance claims. This approach allows for a detailed mapping of patient disease trajectories and care pathways, identifying areas where patients need more adequate treatments (Figure 1).",
          "AI-driven insights help define areas of patient need, quantify treatment journeys, and highlight variations in care across different stakeholders. This granular understanding enables Medical Affairs to develop targeted strategies that address specific unmet needs, ultimately improving patient care.",
        ],
        img: img2,
        imgText:
          "Figure 1. The Clinica AI Medical Affairs suite allows for detailed mapping of patient disease trajectories and care pathways, identifying areas of opportunity to improve patient care. ",
      },
      {
        title: "Targeted Engagement",
        description: [
          `Medical Science Liaisons (MSLs) play a crucial role in bridging the gap between clinical research and patient care. By leveraging AI-based Healthcare Provider (HCP) segmentation, Clinica AI empowers MSLs to engage with healthcare professionals (HCPs) more effectively. AI identifies key segments of HCPs based on their care patterns and specialties, allowing MSLs to tailor their engagement strategies to maximize impact.`,
          `For example, AI can pinpoint HCPs who are not adhering to optimal treatment guidelines or those using suboptimal therapies (Figure 2). By focusing on these segments, MSLs can provide targeted education and resources to promote best practices and improve patient outcomes. This approach not only enhances the effectiveness of MSL interactions but also ensures that the right information reaches the right HCPs at the right time.`,
        ],
        img: MA_Article_Figure_2,
        imgText:
          "Figure 2. Clinica AI allows Life Sciences’ MSLs to segment HCPs by areas of opportunity and tailor engagement with them to improve patient outcomes",
      },
      {
        title: "Tailored Messaging",
        description: [
          "In the digital age, engagement strategies must evolve to meet the needs of a diverse and fragmented HCP population. Clinica AI's tailored digital engagement solutions use AI to customize content and messaging for different HCP segments (Figure 3). This approach ensures that digital communications are relevant and impactful, addressing the specific needs and preferences of each HCP.",
          "For instance, AI can analyze HCPs' digital behavior and preferences to optimize the frequency, content, and channel of communication. Whether through email, webinars, or social media, tailored digital engagement ensures that HCPs receive timely and pertinent information that supports their clinical decision-making. This personalized approach enhances the overall effectiveness of digital engagement, leading to better patient care.",
        ],
        img: Image4,
        imgText:
          "Figure 3. The platform allows for segmentation of HCPs and tailor messaging to increase engagement and ultimately improve patient outcomes.",
      },
      {
        title: "Evidence Generation and Digital",
        description: [
          "As MSLs engage and use our Medical Affairs suite, the platform gathers data on the initiatives being implemented and their impact on patient care pathways (Figure 4). This information can be used to develop materials and evidence to further engage other HCPs in best practices.",
        ],
        img: Image5,
        imgText:
          "Figure 4. Clinica AI gathers data for MSLs to create materials and further engage HCPs.",
      },
      {
        title: "Impact Tracking and Refinement",
        description: [
          "Continuous improvement is essential in addressing healthcare disparities. Clinica AI's impact tracking and refinement tools provide real-time insights into the effectiveness of Medical Affairs initiatives (Figure 5). By monitoring key metrics such as patient outcomes, HCP engagement, and adherence to treatment guidelines, Medical Affairs can assess the impact of their strategies and make data-driven adjustments as needed.",
          "AI-powered impact tracking allows for dynamic optimization of initiatives based on real-time data. For example, if a particular intervention is not yielding the desired results, Medical Affairs can quickly identify the issue and refine their approach. This iterative process ensures that interventions remain effective and aligned with evolving patient needs and healthcare landscapes.",
        ],
        img: Image6,
        imgText:
          "Figure 5. Clinica AI enables MSLs to track the impact of initiatives down to the individual physician level and in real time.",
      },
      {
        title: "",
        description: [
          "Addressing disparities in healthcare requires a comprehensive approach that leverages the power of data and AI. Clinica AI's solutions empower Medical Affairs to develop optimal strategies, engage HCPs more effectively, tailor digital communications, and continuously refine their initiatives. By harnessing these tools, Pharmaceutical and BioTech companies will be more effective in making sure that patients receive the best possible treatment, regardless of their HCP, insurance plan, or geographic location.",
        ],
      },
    ],
    sources: [
      "Shafrin, J., Marijam, A., Joshi, A. V., Mitrani-Gold, F. S., Everson, K., Tuly, R., Rosenquist, P., Gillam, M., & Ruiz, M. E. (2022). Impact of suboptimal or inappropriate treatment on healthcare resource use and cost among patients with uncomplicated urinary tract infection: An analysis of integrated delivery network electronic health records. BMC Health Services Research. https://aricjournal.biomedcentral.com/articles/10.1186/s13756-022-01170-3",
      "Appaneal, H. J., Shireman, T. I., Lopes, V. V., Mor, V., Dosa, D. M., LaPlante, K. L., & Caffrey, A. R. (2021). Poor clinical outcomes associated with suboptimal antibiotic treatment among older long-term care facility residents with urinary tract infection: A retrospective cohort study. BMC Geriatrics. https://bmcgeriatr.biomedcentral.com/articles/10.1186/s12877-021-02378-5",
      "Fernandez-Lazaro, C. I., García-González, J. M., Adams, D. P., Fernandez-Lazaro, D., Mielgo-Ayuso, J., Caballero-Garcia, A., Moreno Racionero, F., Córdova, A., & Miron-Canelo, J. A. (2019). Adherence to treatment and related factors among patients with chronic conditions in primary care: A cross-sectional study. BMC Family Practice. https://bmcprimcare.biomedcentral.com/articles/10.1186/s12875-019-1019-3",
      "LaVeist, T. A., Pérez-Stable, E. J., & Richard, P. (2023). The economic burden of racial, ethnic, and educational health inequities in the US. JAMA Network. https://jamanetwork.com/journals/jama/article-abstract/2804818",
      "U.S. Food and Drug Administration. (2023). Considerations for the use of real-world data and real-world evidence to support regulatory decision-making for drug and biological products: Guidance for industry. FDA. https://www.fda.gov/media/133660/download",
      "Stapff, M., & Stacey, J. (2019). How can real-world data support clinical trials and medical research? The Association of Clinical Research Professionals. https://acrpnet.org/2019/01/15/how-can-real-world-data-support-clinical-trials-and-medical-research/",
    ],
  },
  {
    name: "Article 2",
    id: "article2",
    caption:
      "Explore how AI and Real-World Data are transforming clinical trials, making them faster, more accurate, and efficient—ensuring life-saving treatments reach patients sooner.",
    articleImg: rndImg1,
    desc: "Conducting Faster, More Accurate Clinical Trials with AI and Real-World Data",
    label:
      "Conducting Faster, More Accurate Clinical Trials with AI and Real-World Data",
    details: [
      {
        title: "",
        description: [],
        img: rndImg1,
      },
      {
        title: "",
        description: [
          "The rapid pace of therapeutic innovation has led to an increasing number of treatments entering clinical trials. However, the traditional clinical trial process is often slow, costly, and prone to various biases that can compromise the accuracy of results. To address these challenges, Clinica AI leverages artificial intelligence (AI) and Real-World Data (RWD) to revolutionize the way clinical trials are conducted, making them faster, more accurate, and more efficient.",
        ],
      },
      {
        title: "Leveraging AI to Predict Patient Outcomes",
        description: [
          `One of the most significant advancements offered by Clinica AI is the development of best-in-class algorithms that predict key patient outcomes with unprecedented accuracy. By integrating trial data with medical records, insurance claims, and other RWD sources, Clinica AI's proprietary machine learning methods can more accurately predict patient outcomes during the recruitment phase. This allows for better balancing of trial arms or groups, reducing unexpected outcome variation and the number of patients required to demonstrate treatment benefits.`,
          "For instance, our Severe COVID Outcomes algorithm, published in The American Journal of Medicine¹, not only identified new risk factors for severe COVID-19 outcomes but also significantly improved the accuracy of predicting these outcomes, which is crucial for designing more effective clinical trials.",
          "Furthermore, the inclusion of the Provider system as a factor captures variations in patient management across different health systems, which were not included in the original SCORS. Similarly, incorporating the State factor brings in the element of geographic variation.",
        ],
        img: rndImg1_1,
        imgText:
          "Table 1. Comparison of various models in predicting severe COVID-19 outcomes. The augmented composite risk model, integrating the Severe COVID Outcomes Risk Score (SCORS) with State (a measure of geographic variation) and Provider system (a measure of site variation), demonstrated substantial improvements in performance metrics2. ",
      },
      {
        title: "Designing More Accurate Trials",
        description: [
          `Traditional clinical trials often face challenges related to randomization bias, where the arms of a trial may not be perfectly balanced in terms of patient risk characteristics. This imbalance can lead to skewed results, making it difficult to assess the true efficacy of a treatment. Clinica AI addresses this issue by developing and applying machine learning-based risk scores, such as the Severe COVID Outcomes Risk Score (SCORS), to enhance trial design.`,
          `In a study involving 100,000 simulated trials, SCORS outperformed traditional covariates in
predicting severe outcomes, reducing the required sample size by 19% while maintaining
statistical significance. By incorporating such advanced risk scores into trial design, we can
increase the accuracy of treatment effect estimations and reduce the number of patients needed,
thus making trials more efficient and less burdensome.`,
        ],
        img: rndImg2,
        imgText:
          "Figure 1. Left: Distribution of Difference in Severe Outcome Rate across 100,000 Simulated Trials of 1,000 Patients Using 3 Stratification Methodologies. Right: Required trial sample size comparing SCORS versus standard covariates assuming 25% and 50% estimated treatment effect.",
      },
      {
        title: "Better Allocation of Patients During Recruitment",
        description: [
          "Patient recruitment is a critical phase in clinical trials, and improper allocation can lead to delays, increased costs, and compromised trial integrity. Clinica AI's real-time patient allocation tool uses AI to dynamically allocate patients to trial arms based on their predicted risk profiles. This method ensures that each arm of the trial is balanced, reducing the likelihood of bias and improving the reliability of trial outcomes.",
          "For example, by balancing trial arms using SCORS rather than traditional methods, we have demonstrated a significant reduction in outcome bias between trial arms. This approach not only improves the accuracy of the trial but also enhances its efficiency, enabling researchers to conduct faster trials with fewer patients while still achieving robust results.",
        ],
        img: rndImg3,
        imgText:
          "Figure 2. Simulated trial comparing two strategies for balancing patient allocation across trial arms. On the left, patients are balanced by standard COVID risk factors; on the right, by the Severe COVID Outcomes Risk Score (SCORS). Both trial arms consist of 500 patients, but the outcome percentages differ, which is a critical point of comparison.",
      },
      {
        title: "Identifying and Correcting Imbalances in Past Trials",
        description: [
          "In addition to improving the design and execution of new trials, Clinica AI's technology can also be applied retrospectively to identify and correct imbalances in past trials. This capability is particularly valuable in cases where surprising or inconclusive results were observed, as it allows researchers to reassess the data with more accurate risk adjustments and potentially rescue valuable findings.",
          "By using AI to analyze trial data from multiple angles, Clinica AI can uncover hidden patterns and biases that may have been missed in the original analysis. This retrospective analysis can lead to new insights, more accurate conclusions, and ultimately, more effective treatments being brought to market.",
          "The integration of AI and RWD in clinical trials represents a transformative shift in how Life Sciences companies can conduct research. Clinica AI's solutions empower R&D teams to develop more accurate predictions, design better trials, allocate patients more effectively, and even correct past trial imbalances. ",
        ],
        img: rndImg4,
        imgText:
          "Figure 3. The 5 key outputs from the Clinica AI R&D suite to improve clinical trials’ design.",
      },

      {
        title: "",
        description: [
          "By leveraging these tools, we can conduct faster, more accurate clinical trials with fewer patients, accelerating the pace of therapeutic innovation and bringing life-saving treatments to patients more quickly².",
        ],
        img: rndImg5,
        imgText:
          "Figure 4. Clinical risk scores applications at each step of the clinical trial process.",
      },
    ],
    sources: [
      "Baker, J. B., Ghatak, A., Cullen, M. R., & Horwitz, R. I. (2023). Development of a novel clinical risk score for COVID-19 infections. The American Journal of Medicine. https://www.amjmed.com/article/S0002-9343(23)00551-X/fulltext",
      `Baker, J. B., Ghatak, A., Cullen, M. R., & Horwitz, R. I. (n.d.). Addressing "randomization bias"—Improving accuracy of clinical trials with machine learning risk adjustment.`,
    ],
  },
  {
    name: "Article 3",
    id: "article3",
    articleImg: covidImg,
    desc: "Severe COVID Outcomes Risk Score",
    label: "Severe COVID Outcomes Risk Score",
    para: [
      "Dive deep into a scientifically-validated assessment of Risk Adjusted Age for Severe COVID Outcomes.",
      "This research is outlined in the research publication ‘Development of a Novel Risk Score for COVID-19 Infections’, featured in the American Journal of Medicine (December, 2023).",
      "Developed as part of our public-private partnership with the COVID-19 Research Database to improve patient care for the public good using data and AI. We do not save any user submitted healthcare information.",
    ],
    button1: "Less than 1 minute to your personalized SCORS",
    button1Desc:
      "A refined risk assessment empowers both patients and healthcare professionals to make informed, safety-first choices.",
    button2: "Best-in-class accuracy",
    button2Desc:
      "Our data represents a comprehensive and representative foundation for COVID risk. The analysis includes COVID events from March 2020 to September 2022.",
  },
  {
    name: "Article 1",
    articleImg: article4Img,
    caption:
      "Clinica   AI’s   groundbreaking   machine   learning   model   revolutionized   COVID-19   risk prediction by accurately forecasting severe outcomes without the need for lab tests,offering new hope for healthcare systems and broader disease applications",
    id: "article4",
    desc: "Advancing Predictive Healthcare: Clinica AI's New AI-Powered COVID-19 Risk Score",
    label:
      "Advancing Predictive Healthcare: Clinica AI's New AI-Powered COVID-19 Risk Score",
    details: [
      {
        title: "",
        description: [],
        img: article4Img,
      },
      {
        title: "",
        description: [
          "At the forefront of the fight against COVID-19, Clinica AI developed an innovative machine learning model that simplified the process of predicting severe outcomes for patients infected with the virus. This new approach, published in The American Journal of Medicine, utilized data such as treatments, diagnoses, and healthcare utilization instead of relying on lab results, setting a new standard for predictive healthcare. By focusing on non-lab data, the model demonstrates significant potential for broader applications across a variety of diseases, providing a highly accurate yet accessible risk score.",
          "The study was based on data from over a million COVID-19 diagnoses across the United States, between March 2020 and September 2022. The researchers from Clinica AI found that their model could outperform similar algorithms, achieving an area under the curve (AUC) of 0.83, a strong indicator of the model’s predictive ability. This is particularly notable because the model avoids using lab data, relying instead on historical healthcare utilization patterns, acute care history, and specific medication patterns to predict severe outcomes like respiratory failure, ICUadmission, or ventilator use",
        ],
      },
      {
        title: "Key Findings from the Study",
        listItems: [
          {
            head: "Higher Accuracy with Less Data",
            data: "Clinica AI's model surpasses comparable algorithms by using only non-lab data to predict severe COVID-19 outcomes. The inclusion of treatment history and healthcare encounters rather than relying on lab test results opens the door for this model to be applied to broader disease prediction efforts. The model's performance indicates that essential predictive insights can be gleaned from healthcare utilization data, which is more widely accessible.",
          },
          {
            head: "Consistency Amidst New Variants",
            data: "Despite the ongoing emergence of new COVID-19 strains, including the Omicron variant, the risk factors for severe outcomes remained consistent. The study uncovered a significant spike in severe outcomes at the onset of each new strain, often 2-3 times higher than in the following months. This consistency underscores the resilience of Clinica AI's model in adapting to changing pandemic dynamics",
          },
          {
            head: "Novel Predictive Factors",
            data: "Beyond traditional risk factors, this study identified previously underexplored elements, such as healthcare utilization patterns and acute care history, as significant predictors of severe outcomes. These insights open new pathways for the development of predictive models that do not rely on invasive tests, making risk prediction more accessible for healthcare providers.",
          },
        ],
        description: [
          "Closing this gap between patients and the best treatment, and thus, addressing disparities in US healthcare requires a multifaceted approach, leveraging data to make targeted interventions through Medical Affairs. However, current sources of data offer incomplete, outdated information, making it a true challenge to really understand patient journeys. Despite the significant improvements in Real World Data (RWD), information covers only ~70% of patients in the US⁵⁶.",
          "New, advanced artificial intelligence techniques help fill in these data gaps, enabling a complete understanding of patient care across healthcare providers, insurance companies, and geographies.",
          "Clinica AI developed artificial intelligence algorithms to analyze RWD from diverse sources such as electronic medical records (EMR) and insurance claims. This approach allows for a detailed mapping of patient disease trajectories and care pathways, identifying areas where patients need more adequate treatments (Figure 1).",
          "AI-driven insights help define areas of patient need, quantify treatment journeys, and highlight variations in care across different stakeholders. This granular understanding enables Medical Affairs to develop targeted strategies that address specific unmet needs, ultimately improving patient care.",
        ],
      },
      {
        title: "Broader Implications for Healthcare",
        description: [
          `The ability to predict severe outcomes in COVID-19 patients, especially without the need for laboratory data, has vast implications for healthcare systems, resource allocation, and patient management. Clinica AI's model not only enhances the accuracy of predictions but also provides a tool that can be readily implemented in diverse settings. This risk score can help healthcare providers prioritize high-risk patients, thereby optimizing care and improving outcomes, especially in situations where lab resources are scarce`,
          `Moreover, the adaptability of the model across various COVID-19 strains suggests that it could serve as a foundation for broader applications. The methodology used by Clinica AI may be applied to other diseases, improving early detection and intervention strategies in healthcare`,
        ],
      },
      {
        title: "Conclusion",
        description: [
          "Clinica AI’s breakthrough model represents a significant advancement in the field of predictive healthcare. By eliminating the need for lab data, the model is not only more accessible but also more versatile. As new COVID-19 strains emerge and healthcare challenges continue to evolve, the model’s ability to consistently and accurately predict severe outcomes positions it as a critical tool for healthcare professionals.",
          "The original research was published in The American Journal of Medicine. As Clinica AI continues to innovate in the field of healthcare technology, this model demonstrates the company's commitment to improving patient care through data-driven solutions",
          "Discover more about Clinica AI and its cutting-edge approach to predictive healthcare in the full publication, available in The American Journal of Medicine.",
        ],
      },
    ],
    // sources: ["https://www.amjmed.com/article/S0002-9343(23)00551-X/fulltext"],
  },
];

const publications = [
  {
    title: "Development of a Novel Clinical Risk Score for COVID-19 Infections",
    sub: "JB Baker, A Ghatak, MR Cullen, RI Horwitz",
    desc: "The American Journal of Medicine 136 (12), 1169-1178. e7",
    year: 2023,
    link: "https://www.amjmed.com/article/S0002-9343(23)00551-X/fulltext",
  },
  {
    title:
      "Randomization Bias, Multi-Morbidity, and the Composite Clinical Score",
    sub: "RI Horwitz, JB Baker, A Ghatak, MR Cullen, ",
    desc: "Psychotherapy and Psychosomotics 8, 9",
    year: 2024,
    link: "https://karger.com/pps/article/doi/10.1159/000539522/909321/Randomization-Bias-Multi-Morbidity-and-the",
  },
];

const ResearchPage = () => {
  const navigate = useNavigate();

  const handleClick = (link) => {
    const newTab = window.open();
    newTab.opener = null;
    newTab.location = `${link}`;
  };

  const handleGoToArticle = (id) => {
    navigate(`/articles/${id}`);
  };

  return (
    <>
      <Navbar />
      <div className="md:px-20 px-10 py-20">
        <h2 className="text-5xl mt-10 font-[500]">Our Research</h2>
        <h2 className="text-4xl mt-10 font-[500]">Articles</h2>
        <div className="text-[#000] grid grid-cols-1 md:grid-cols-4 mt-10 items-center gap-4 ">
          {articles.map((item) => (
            <div
              // onClick={() => handleGoToArticle(item.id)}
              className="border-2 cursor-pointer min-h-[40rem] flex flex-col items-start justify-between p-6 hover:scale-[1.025] rounded-xl transform-all ease-in-out duration-200"
            >
              <div className="w-full h-[15rem] border-2">
                {item.articleImg && (
                  <img
                    src={item.articleImg}
                    alt="article"
                    className="h-full object-contain"
                  />
                )}
              </div>
              <div className="text-md md:text-xl mt-10 font-[500]">
                {item.desc}
              </div>
              <div className="text-sm md:text-lg mt-10">{item.caption}</div>
              <PrimaryBtn
                text={"View More"}
                bg={"transparent"}
                onClick={() => handleGoToArticle(item.id)}
                className={
                  "px-10 mt-4 before:absolute before:left-0 relative rounded-lg z-[10] border text-[#000] border-[#000] hover:text-[#fff] before:top-0 before:bg-[#000]  before:-z-2 overflow-hidden before:overflow-hidden before:w-full before:h-0 hover:before:top-[unset] hover:before:h-full hover:before:bottom-0 before:transition-all before:duration-200 before:ease-in-out md:text-2xl text-md hover:scale-105]"
                }
              />
            </div>
          ))}
        </div>
        <h2 className=" md:text-4xl  text-xl mt-20 font-[500]">
          Scientific Publications
        </h2>
        <div className="flex flex-col items-start mt-10 gap-6">
          <div className="flex w-full border-b border-b-black p-4 text-3xl justify-between gap-4 items-start">
            <div className="flex flex-col items-start gap-1">Title</div>
            <div>Year</div>
          </div>
          {publications.map((item) => (
            <div
              onClick={() => handleClick(item.link)}
              className="flex cursor-pointer hover:bg-[#c4c4c4] px-4 w-full justify-between  items-start"
            >
              <div className="flex text-xl text-gray-800 flex-col items-start">
                <div className="md:text-3xl text-lg text-[#000]">
                  {item.title}
                </div>
                <div>{item.sub}</div>
                <div>{item.desc}</div>
              </div>
              <div className="md:text-2xl text-lg font-[500]">{item.year}</div>
            </div>
          ))}
        </div>
      </div>
      <ContactFooter />
      <Footer />
    </>
  );
};

export default ResearchPage;
