// DemoContact.jsx
import React, { useRef, useState } from "react";
import InputField from "../InputField";
import PrimaryBtn from "../PrimaryBtn";
import { IoCloseCircle } from "react-icons/io5";
import contactImg from "../../assets/images/contact.png";
import emailjs from "emailjs-com";
import Popup from "reactjs-popup";
import { AiOutlineCheckCircle } from "react-icons/ai";

const inputs = [
  {
    label: "Name",
    name: "user_name",
    type: "text",
  },
  {
    label: "Email",
    name: "user_email",
    type: "email",
  },
  {
    label: "Company",
    name: "company",
    type: "text",
  },
];

const DemoContact = ({ handleClose }) => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "clinica_contact",        // Your Service ID
        "template_demo_contact",  // Your new Template ID
        formRef.current,
        "XsQHL9-BC3BJMc89O"       // Your Public Key (User ID)
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowAlert(true);
          setLoading(false);
        },
        (error) => {
          console.log("There was an error sending the email:", error.text);
          alert("An error occurred, please try again.");
          setLoading(false);
        }
      );
  };

  const closeModal = () => {
    setShowAlert(false);
    handleClose();
  };

  return (
    <div className="flex md:py-0 my-10 max-h-[80vh] md:max-h-[100%] overflow-y-auto flex-col relative md:flex-row items-center justify-center">
      <IoCloseCircle
        className="fixed top-12 right-4 cursor-pointer hover:scale-105 transition-all ease-in-out duration-150"
        size={30}
        onClick={handleClose}
      />
      <img src={contactImg} alt="Contact" className="w-[40rem] md:mt-0 mt-40" />
      <div className="flex justify-center rounded-xl md:gap-6 gap-2 w-full flex-col items-center bg-white md:px-10 px-0 py-10">
        <h2 className="text-2xl font-[500]">Request a demo</h2>
        <form ref={formRef} className="w-full" onSubmit={handleSubmit}>
          <div className="grid md:gap-4 gap-2 w-full grid-cols-1">
            {inputs.map((item) => (
              <InputField input={item} key={item.name} />
            ))}
            <div className="mb-4">
              <label
                className="block text-gray-500 text-sm font-[500] mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                required
                id="message"
                name="message"
                className="shadow appearance-none border-[#c4c4c4] rounded w-full py-2 px-3 text-[#000] leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Your message"
                rows="4"
              ></textarea>
            </div>
          </div>
          <PrimaryBtn
            disabled={loading}
            text={loading ? "Submitting..." : "Submit"}
            className="w-full bg-[#0A1172] hover:scale-105 transition-all ease-in-out duration-200 text-[#fff]"
          />
        </form>
      </div>
      <Popup
        onClose={closeModal}
        modal
        open={showAlert}
        position="center center"
      >
        <div className="flex justify-center rounded-lg gap-6 flex-col items-center bg-white h-[40vh] w-[40vw]">
          <AiOutlineCheckCircle size={200} color="green" />
          <h2 className="font-[600] text-center text-xl">
            Your request has been successfully submitted.
            <br /> Thank you for contacting us!
          </h2>
        </div>
      </Popup>
    </div>
  );
};

export default DemoContact;
