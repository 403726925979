// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#app__layout header {
  grid-area: header;
}
#app__layout #page__container {
  grid-area: content;
}
#app__layout aside {
  grid-area: sidebar;
  top: 56px;
  height: calc(100vh - 58px);
}
#app__layout {
  align-items: flex-start;
  justify-content: flex-start;
  grid-template-columns: 1fr 5fr;
  grid-template-areas: 
    "header  header"
    "sidebar content";
}





@media only screen and (max-width: 712px)  {

#app__layout {
  align-items: flex-start;
  justify-content: flex-start;
  grid-template-columns: 1fr 5fr;
  grid-template-areas: 
    "none  none"
    "none none";
}
}
/* 
.page__content {
  max-width: calc(100vw - 20%)
} */`, "",{"version":3,"sources":["webpack://./src/layouts/DashboardLayout/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,0BAA0B;AAC5B;AACA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,8BAA8B;EAC9B;;qBAEmB;AACrB;;;;;;AAMA;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,8BAA8B;EAC9B;;eAEa;AACf;AACA;AACA;;;GAGG","sourcesContent":["#app__layout header {\n  grid-area: header;\n}\n#app__layout #page__container {\n  grid-area: content;\n}\n#app__layout aside {\n  grid-area: sidebar;\n  top: 56px;\n  height: calc(100vh - 58px);\n}\n#app__layout {\n  align-items: flex-start;\n  justify-content: flex-start;\n  grid-template-columns: 1fr 5fr;\n  grid-template-areas: \n    \"header  header\"\n    \"sidebar content\";\n}\n\n\n\n\n\n@media only screen and (max-width: 712px)  {\n\n#app__layout {\n  align-items: flex-start;\n  justify-content: flex-start;\n  grid-template-columns: 1fr 5fr;\n  grid-template-areas: \n    \"none  none\"\n    \"none none\";\n}\n}\n/* \n.page__content {\n  max-width: calc(100vw - 20%)\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
